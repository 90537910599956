import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';

@Component({
  selector: 'app-loading-message',
  standalone: true,
  templateUrl: './loading-message.component.html',
  styleUrl: './loading-message.component.scss'
})
export class LoadingMessageComponent implements OnInit, OnDestroy {
  private readonly counter = signal(0);

  public readonly text = computed(() => {
    return `${Array.from(new Array(this.counter())).map(() => {
      return '.'
    }).join('')}`
  });

  private interval?: number;
  ngOnInit(): void {
      this.interval = window.setInterval(() => {
        const counter = this.counter();
        if (counter >= 3) {
          this.counter.set(0);
        }
        else {
          this.counter.set(counter + 1);
        }
      }, 500)
  }

  ngOnDestroy(): void {
    window.clearInterval(this.interval);
  }
}
