<app-chat-view
  [style.--primary-color]="appConfig()?.primaryColor"
  [logo]="appConfig()?.logo"
  [messages]="messages()"
  [suggestedMessages]="suggestedMessages()"
  [loading]="loading()"
  [maximized]="maximized()"
  [showMaximizeMinimizeIcon]="embedded"
  [showCloseIcon]="embedded"
  [hideAppBar]="hideAppBar()"
  (newMessage)="newMessage($event)"
  (suggestedMessageClick)="onSuggestedMessageClick($event)"
  (newConversation)="newConversation()"
  (maximize)="onMaximize()"
  (minimize)="onMinimize()"
  (close)="onClose()"
  (linkClick)="linkClick($event)"
  (downloadClick)="onDownload()"
></app-chat-view>
