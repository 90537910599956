import { afterNextRender, Component, computed, ElementRef, inject, input, OnDestroy, output, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Message, SuggestedMessage } from '../../services/chat.service';
import { LoadingMessageComponent } from '../loading-message/loading-message.component';

@Component({
  selector: 'app-chat-view',
  standalone: true,
  imports: [RouterOutlet, LoadingMessageComponent],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatViewComponent implements OnDestroy {
  private readonly el = inject(ElementRef);

  public menuExpanded = signal<boolean>(false);

  public messages = input.required<Message[]>();
  public suggestedMessages = input<SuggestedMessage[]>();
  public logo = input<string>();

  public loading = input<boolean>();
  public maximized = input<boolean>(false);
  public showMaximizeMinimizeIcon = input<boolean>(false);
  public showCloseIcon = input<boolean>(false);
  public hideAppBar = input<boolean>(false);

  public newMessage = output<string>();
  public suggestedMessageClick = output<SuggestedMessage>();
  public newConversation = output<void>();
  public maximize = output<void>();
  public minimize = output<void>();
  public close = output<void>();
  public linkClick = output<{
    link: string;
    label: string;
  }>();
  public downloadClick = output<void>();

  public readonly communicationStarterMessages = computed(() => {
    const suggestedMessages = this.suggestedMessages();
    const messages = this.messages();

    if (!messages.length) {
      return suggestedMessages;
    }

    return [];
  });

  constructor() {
    window.document.addEventListener('click', this.collapseMenu);
    window.document.addEventListener('keydown', this.documentKeyDown);
    afterNextRender(() => {
      this.focusInput();
    });
  }

  private readonly documentKeyDown = (evt: KeyboardEvent) => {
    this.focusInput();
  }

  public focusInput() {
    this.el.nativeElement.querySelector('input').focus();
  }

  public onKeyDown(evt: KeyboardEvent) {
    evt.stopPropagation();
    if (this.loading()) {
      return;
    }

    const target = evt.target as HTMLInputElement;
    const value = target.value.trim();

    if(evt.key.toLowerCase() === 'enter') {
      target.value = '';
      if (value) {
        this.newMessage.emit(value);
      }
    }
  }

  public addNewMessage(input: HTMLInputElement) {
    if (this.loading()) {
      return;
    }
    const value = input.value.trim();

    input.value = '';

    if (value) {
      this.newMessage.emit(value);
    }
  }
  public scrollToBottom() {
    setTimeout(() => {
      const messagesContainer = (this.el.nativeElement as HTMLElement).getElementsByClassName('content-inner')[0] as HTMLDivElement;
      window.scroll(0, messagesContainer.scrollHeight);
    }, 0);
  }
  public scrollToLastUserMessage() {
    setTimeout(() => {
      const el$ = (this.el.nativeElement as HTMLElement);

      const allUserMessages = el$.querySelectorAll('.user.message');
      const lastUserMessage = allUserMessages[allUserMessages.length - 1] as HTMLDivElement;

      if (lastUserMessage) {
        window.scroll(0, lastUserMessage.offsetTop - lastUserMessage.clientHeight - 0)
      }

    }, 0);
  }

  public startNewConversation() {
    this.newConversation.emit();
  }

  public onSuggestedMessageClick(suggestedMessage: SuggestedMessage) {
    this.suggestedMessageClick.emit(suggestedMessage);
  }

  public messageClicked(message: Message, evt: MouseEvent) {
    const target = evt.target as HTMLAnchorElement;
    if (target.tagName.toLowerCase() === 'a') {
      evt.preventDefault();
      this.linkClick.emit({
        link: target.href,
        label: target.innerText
      });
    }
  }

  public toggleExpanded(evt: MouseEvent) {
    evt.stopPropagation();
    this.menuExpanded.set(!this.menuExpanded())
  }

  private collapseMenu = () => {
    if (this.menuExpanded()) {
      this.menuExpanded.set(false);
    }
  }

  public onDownload() {
    this.downloadClick.emit();
  }

  public ngOnDestroy(): void {
    window.document.removeEventListener('click', this.collapseMenu);
    window.document.removeEventListener('keydown', this.documentKeyDown);
  }

  public emitClose() {
    this.close.emit();
  }
}
