import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService, localStorageOrganizationKey, localStorageThreadIdKey } from '../../services/chat.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  private readonly chatService = inject(ChatService);
  private readonly router = inject(Router);

  private readonly activatedRoute = inject(ActivatedRoute);

  public constructor() {
    this.redirectToSavedThread();
  }

  private async redirectToSavedThread() {
    const organizationKeyFromParam = this.activatedRoute.snapshot.paramMap.get('organizationKey')?.trim();
    const organizationKeyFromLocalStorage = localStorage.getItem(localStorageOrganizationKey)?.trim();
    const organizationKey = organizationKeyFromParam || organizationKeyFromLocalStorage || 'sitemule'; // TODO: remove default organization
    if (!organizationKey) {
      throw `This should not happen`;
    }
    const threadIdFromLocalStorage = localStorage.getItem(localStorageThreadIdKey)?.trim();
    const shouldWeUseThreadIdFromLocalStorage = threadIdFromLocalStorage && organizationKey === organizationKeyFromLocalStorage;
    // We shouldn't use the stored threadId of the organization doesn't match the stored organizationKey.
    const threadId = shouldWeUseThreadIdFromLocalStorage ? threadIdFromLocalStorage : undefined;

    Promise.resolve(threadId).then(id => {
      if (id) {
        return id;
      }
      return this.chatService.startNewThread(organizationKey);
    }).then(threadId => {
      this.router.navigate([`/${organizationKey}/${threadId}`], {
        queryParamsHandling: 'merge'
      });
    });
  }
}
