import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private downloadContent(fileName: string, content: Blob) {
    const url = URL.createObjectURL(content);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 2 * 60 * 1000);
  }

  public download(fileName: string, data: string) {
    const blob = new Blob(
      [
        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
        data
      ],
      { type: 'text/html;charset=utf-8' }
    );
    this.downloadContent(fileName, blob);
  }
}
