@if (!hideAppBar()) {
  <div class="app-bar">
    @if (showMaximizeMinimizeIcon()) {
      @if (maximized()) {
        <button (click)="minimize.emit()" title="Minimize">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path d="M440-440v240h-80v-160H200v-80h240Zm160-320v160h160v80H520v-240h80Z"/>
          </svg>
        </button>
      }
      @else {
        <button (click)="maximize.emit()" title="Maximize">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path d="M200-200v-240h80v160h160v80H200Zm480-320v-160H520v-80h240v240h-80Z"/>
          </svg>
        </button>
      }
      <div class="vertical-line"></div>
    }
    <div class="logo">
      @if (logo()) {
        <img src="{{logo()}}" />
      }
    </div>
    @if (messages().length) {
      <div class="vertical-line"></div>
      <button (click)="onDownload()" title="Download chat">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
          <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/>
        </svg>
      </button>
      <div class="vertical-line"></div>
      <button title="Ny" (click)="startNewConversation()" title="Reset chat">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
        </svg>
      </button>
    }
    @if (showCloseIcon()) {
      <div class="vertical-line"></div>
      <button (click)="emitClose()" title="Close">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
      </button>
    }
  </div>
}

<div class="content">
  <div class="content-inner">
    @for (message of messages(); track message.id; let idx = $index;) {
      <div class="message {{message.role}} {{message.loading ? 'loading' : ''}}">
        <div class="message-top">
          <svg class="message-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path d="M476-280q21 0 35.5-14.5T526-330q0-21-14.5-35.5T476-380q-21 0-35.5 14.5T426-330q0 21 14.5 35.5T476-280Zm-36-154h74q0-17 1.5-29t6.5-23q5-11 12.5-20.5T556-530q35-35 49.5-58.5T620-642q0-53-36-85.5T487-760q-55 0-93.5 27T340-658l66 26q7-27 28-43.5t49-16.5q27 0 45 14.5t18 38.5q0 17-11 36t-37 42q-17 14-27.5 27.5T453-505q-7 15-10 31.5t-3 39.5Zm40 394L360-160H200q-33 0-56.5-23.5T120-240v-560q0-33 23.5-56.5T200-880h560q33 0 56.5 23.5T840-800v560q0 33-23.5 56.5T760-160H600L480-40ZM200-240h192l88 88 88-88h192v-560H200v560Zm280-280Z"/>
          </svg>
          @if (message.loading) {
            <app-loading-message />
          }
        </div>
        <div class="message-content" [innerHTML]="message.content" (click)="messageClicked(message, $event)">
        </div>
        @if (idx === (messages().length - 1) && message.followUpMessages.length) {
          <div class="suggested-messages">
            @for (followupMessage of message.followUpMessages; track followupMessage.content) {
              <div class="suggested-message" (click)="onSuggestedMessageClick(followupMessage)">
                <div class="suggested-message-content">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                    <path d="M476-280q21 0 35.5-14.5T526-330q0-21-14.5-35.5T476-380q-21 0-35.5 14.5T426-330q0 21 14.5 35.5T476-280Zm-36-154h74q0-17 1.5-29t6.5-23q5-11 12.5-20.5T556-530q35-35 49.5-58.5T620-642q0-53-36-85.5T487-760q-55 0-93.5 27T340-658l66 26q7-27 28-43.5t49-16.5q27 0 45 14.5t18 38.5q0 17-11 36t-37 42q-17 14-27.5 27.5T453-505q-7 15-10 31.5t-3 39.5Zm40 394L360-160H200q-33 0-56.5-23.5T120-240v-560q0-33 23.5-56.5T200-880h560q33 0 56.5 23.5T840-800v560q0 33-23.5 56.5T760-160H600L480-40ZM200-240h192l88 88 88-88h192v-560H200v560Zm280-280Z"/>
                  </svg>
                  <div [innerHTML]="followupMessage.content">
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    @for (communicationStarterMessage of communicationStarterMessages(); track communicationStarterMessage.content) {
      <div class="suggested-message" (click)="onSuggestedMessageClick(communicationStarterMessage)">
        <div class="suggested-message-content">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
            <path d="M476-280q21 0 35.5-14.5T526-330q0-21-14.5-35.5T476-380q-21 0-35.5 14.5T426-330q0 21 14.5 35.5T476-280Zm-36-154h74q0-17 1.5-29t6.5-23q5-11 12.5-20.5T556-530q35-35 49.5-58.5T620-642q0-53-36-85.5T487-760q-55 0-93.5 27T340-658l66 26q7-27 28-43.5t49-16.5q27 0 45 14.5t18 38.5q0 17-11 36t-37 42q-17 14-27.5 27.5T453-505q-7 15-10 31.5t-3 39.5Zm40 394L360-160H200q-33 0-56.5-23.5T120-240v-560q0-33 23.5-56.5T200-880h560q33 0 56.5 23.5T840-800v560q0 33-23.5 56.5T760-160H600L480-40ZM200-240h192l88 88 88-88h192v-560H200v560Zm280-280Z"/>
          </svg>
          <div [innerHTML]="communicationStarterMessage.content">
          </div>
        </div>
      </div>
    }
  </div>
</div>
<div class="footer">
  <div class="input-container">
    <div class="input">
      <input
        #input
        [placeholder]="loading() ? 'Loading...' : 'Skriv dit spørgsmål her…'"
        (keydown)="onKeyDown($event)">

      <button [disabled]="!input.value" (click)="addNewMessage(input)">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
          <path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z"/>
        </svg>
      </button>
    </div>
  </div>
  <div class="powered-by">
    Powered by
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
      <path d="M720-440v-80h160v80H720Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM200-200v-160h-40q-33 0-56.5-23.5T80-440v-80q0-33 23.5-56.5T160-600h160l200-120v480L320-360h-40v160h-80Zm240-182v-196l-98 58H160v80h182l98 58Zm120 36v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346ZM300-480Z"/>
    </svg>
    <a href="https://capa.bseen.ai" target="_blank">Bseen</a>
  </div>
</div>
