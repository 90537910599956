import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { marked } from 'marked';
import { firstValueFrom, map } from 'rxjs';

export const localStorageThreadIdKey = 'thread-id';
export const localStorageOrganizationKey = 'organization';

const fixLinkWithSpace = (markDown: string) => {
  return markDown.replace(/(\[[^\]]*\])\(([^)]*)\)/g, '$1(<$2>)');
}

const fixMarkdown = (markDown: string) => {
  return fixLinkWithSpace(markDown);
}

const markdownToHtml = (markDown: string) => {
  return  marked.parse(markDown.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/,"")) as string;
}

// So we can add horizontal scroll on table
const wrapTableAroundTableContainer = (html: string) => {
  const tableIndex = html.indexOf('<table');
  if (tableIndex > -1) {
    const tableEndIndex = html.indexOf('</table>');
    const table = html.slice(tableIndex, tableEndIndex + 8);
    return html.replace(table, `<div class="table-container">${table}</div>`);
  }
  return html;
}

const fixHtml = (html: string) => {
  return wrapTableAroundTableContainer(html).trim();
}

export const openAIMessageContentToHTML = (markdown: string) => {
  // Remove all unhandled annotations
  const markDownWithRemovedAnnotation = markdown.replace(/【[^】]+】/g, '');

  return fixHtml(markdownToHtml(fixMarkdown(markDownWithRemovedAnnotation)));
}

export const extractFollowupQuestions = (html: string) => {
  const identifier = '¿';
  if (html.endsWith('</p>')) {
    const index = html.lastIndexOf('<p>');
    const list = html.substring(index,);
    if (list.includes(identifier)) {
      const content = html.substring(0, index);
      return {
        content,
        followUpMessages: list
          .replace('<p>','')
          .replace('</p>', '')
          .split(identifier)
          .map(i => i.trim())
          .filter(i => !!i)
          .map(content => {
            return {
              content
            }
          }),
      }
    }
    return {
      content: html,
      followUpMessages: [],
    }
  }
  return {
    content: html,
    followUpMessages: [],
  }
}

export interface Message {
  id: string;
  loading?: boolean;
  content: string;
  role: 'user' | 'assistant';
  followUpMessages: SuggestedMessage[]
}

export interface SuggestedMessage {
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
    private readonly http = inject(HttpClient);

  public initializeThread() : Promise<void> {
    return Promise.resolve();
  }

  public async startNewThread(organizationKey: string): Promise<string> {
    return firstValueFrom(this.http.post<{ id: string }>(`https://capa-api.bseen.ai/public/assistants/${organizationKey}/threads`, {})
      .pipe(map(res => res.id)));
  }


  public createNewMessage(organizationKey: string, threadId: string, message: string) {
    return new EventSource(`https://capa-api.bseen.ai/public/assistants/${organizationKey}/threads/${threadId}/messages?message=${message}`);
  }

  public getAllMessages(organizationKey: string, threadId: string): Promise<Message[]> {
    return firstValueFrom(this.http.get<{
      id: string;
      message: string;
      sender: 'user' | 'assistant';
      createdAt: Date;
  }[]>(`https://capa-api.bseen.ai/public/assistants/${organizationKey}/threads/${threadId}`)
      .pipe(map(res => {
        return res.map(item => {
          const { content, followUpMessages } = extractFollowupQuestions(openAIMessageContentToHTML(
            item.message
          ));
          return {
            id: item.id,
            content,
            role: item.sender,
            followUpMessages,
          }
      });
    })));
  }

  public getConversationStarters(organizationKey: string, url: string): Promise<string[]> {
    return firstValueFrom(this.http.get<{
      items: string[];
  }>(`https://capa-api.bseen.ai/public/assistants/${organizationKey}/starters`, {
    params: {
      url
    }
  })
      .pipe(map(res => {
        return res.items;
    })));
  }
}
