import { Component, inject, OnDestroy, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ChatViewComponent } from '../../components/chat/chat.component';
import {
  ChatService,
  extractFollowupQuestions,
  localStorageOrganizationKey,
  localStorageThreadIdKey,
  Message,
  openAIMessageContentToHTML,
  SuggestedMessage,
} from '../../services/chat.service';
import { AppConfig, ConfigService } from '../../services/config.service';
import { DownloadService } from '../../services/download.service';

type WindowInputEvent = {
  type: 'sitemule-ai-callback';
  key: string;
  data:
    | {
        type: 'ask-chat-gpt';
        prompt: string;
      }
    | {
        type: 'message';
        message: string;
      }
    | {
        type: 'suggestions';
        suggestions: string[];
      }
    | {
        type: 'new-conversation';
      }
    | {
        type: 'download';
      }
    | {
        type: 'maximize';
      }
    | {
        type: 'minimize';
      }
    | {
      type: 'focus-input';
    }
    | {
      type: 'messages-count';
    };
};

type WindowOutEvent =
  | {
      type: 'maximize';
    }
  | {
      type: 'message-link-click';
      link: string;
      label: string;
    }
  | {
      type: 'minimize';
    }
  | {
      type: 'initialized';
    }
  | {
      type: 'close';
    }
  | {
      type: 'new-conversation';
    }
  | {
      type: 'suggestions';
      suggestions: string[];
    }
  | {
      type: 'sitemule-ai-callback';
      key: string;
      data:
        | {
            error: any;
          }
        | {
            type: 'ask-chat-gpt';
            prompt: string;
            response: string;
          }
        | {
            type: 'message';
            message: string;
          }
        | {
            type: 'suggestions';
            suggestions: string[];
          }
        | {
            type: 'new-conversation';
          }
        | {
            type: 'download';
          }
        | {
            type: 'maximize';
          }
        | {
            type: 'minimize';
          }
        | {
            type: 'focus-input';
        }
        | {
          type: 'messages-count';
          count: number;
        };
    };

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [ChatViewComponent],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class ChatComponent implements OnDestroy {
  private readonly chatService = inject(ChatService);
  private readonly configService = inject(ConfigService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly downloadService = inject(DownloadService);
  private readonly router = inject(Router);

  @ViewChild(ChatViewComponent)
  private readonly chatComponent!: ChatViewComponent;

  public readonly messages = signal<Message[]>([]);
  public readonly suggestedMessages = signal<SuggestedMessage[]>([]);
  public readonly loading = signal<boolean>(false);
  public readonly hideAppBar = signal<boolean>(
    !!this.activatedRoute.snapshot.queryParamMap.get('hideAppBar')
  );
  public readonly maximized = signal<boolean>(
    !!this.activatedRoute.snapshot.queryParamMap.get('maximized')
  );

  public readonly embedded = window.parent !== window;
  public readonly threadId = signal<string | undefined>(undefined);
  public readonly organizationKey = signal<string | undefined>(undefined);
  public readonly appConfig = signal<AppConfig | undefined>(undefined);

  private initialized = false;

  private sub1 = this.activatedRoute.paramMap.subscribe((p) => {
    const threadId = p.get('threadId')?.trim() || undefined;
    const organizationKey = p.get('organizationKey')?.trim() || undefined;
    if (!threadId || !organizationKey) {
      throw `Missing threadId or organizationKey`;
    }

    localStorage.setItem(localStorageThreadIdKey, threadId);
    localStorage.setItem(localStorageOrganizationKey, organizationKey);
    this.loading.set(true);
    this.threadId.set(threadId);
    this.organizationKey.set(organizationKey);
    this.configService.getConfig(organizationKey).then((config) => {
      this.appConfig.set(config);
    });
    this.chatService
      .getAllMessages(organizationKey, threadId)
      .then((messages) => {
        this.messages.set(messages);
        this.loading.set(false);
        this.triggerInitialized();
        this.scrollToLastUserMessage();
      });
  });

  constructor() {
    window.addEventListener('message', this.windowsEventListener, false);
  }

  public newMessage(message: string): Promise<void> {
    return new Promise((res, rej) => {
      const threadId = this.threadId();
      const organizationKey = this.organizationKey();
      if (!threadId || !organizationKey) {
        rej(`Empty threadId`);
        return;
      }
      this.loading.set(true);
      const temporaryUserMessage = {
        id: uuidv4(),
        content: message,
        role: 'user' as const,
        followUpMessages: [],
      };
      const temporaryAssistantMessage = {
        id: uuidv4(),
        content: '',
        loading: true,
        role: 'assistant' as const,
        followUpMessages: [],
      };
      this.setSuggestedMessages([]);
      this.messages.set([...this.messages(), temporaryUserMessage, temporaryAssistantMessage]);
      this.scrollToLastUserMessage();
      this.chatService
        .createNewMessage(organizationKey, threadId, message)
        .then(([createdMessageId, stream]) => {
          const clonedMessages = [...this.messages()];
          const index = clonedMessages.findIndex(
            (m) => m.id === temporaryUserMessage.id
          );
          clonedMessages[index].id = createdMessageId;
          this.messages.set(clonedMessages);

          stream.on('messageDelta', (delta, message) => {
            const clonedMessages = [...this.messages()];
            let index = clonedMessages.findIndex((m) => m.id === message.id);
            // Update the ID if not found
            if (index < 0) {
              index = clonedMessages.findIndex(m => m.id === temporaryAssistantMessage.id);
              clonedMessages[index].id = message.id;
            }
            const { content } = extractFollowupQuestions(openAIMessageContentToHTML(
              message.content
            ));
            clonedMessages[index].content = content;
            clonedMessages[index].followUpMessages = [];
            this.messages.set(clonedMessages);
          });
          stream.on('messageDone', (message) => {
            this.loading.set(false);

            const clonedMessages = [...this.messages()];
            const index = clonedMessages.findIndex((m) => m.id === message.id);
            if (index < 0) {
              throw `This should not happen`;
            }
            const { content, followUpMessages } = extractFollowupQuestions(openAIMessageContentToHTML(
              message.content
            ));
            clonedMessages[index].content = content;
            clonedMessages[index].followUpMessages = followUpMessages;
            delete clonedMessages[index].loading;
            this.messages.set(clonedMessages);

            res();
          });
          stream.on('error', (err) => {
            this.loading.set(false);
            rej(err);
          });
        })
        .catch((err) => {
          const clonedMessages = [...this.messages()];
          const index = clonedMessages.findIndex(
            (m) => m.id === temporaryUserMessage.id
          );
          clonedMessages.splice(index, 1);
          this.messages.set(clonedMessages);
          this.loading.set(false);
          rej(err);
        });
    });
  }

  public newConversation() {
    localStorage.removeItem(localStorageThreadIdKey);
    this.postMessage({
      type: 'new-conversation'
    });
    this.router.navigate([`/`], {
      queryParamsHandling: 'merge',
    });
  }

  private setSuggestedMessages(messages: string[]) {
    this.suggestedMessages.set(
      messages.map((s) => {
        return {
          content: s,
        };
      })
    );

    this.postMessage({
      type: 'suggestions',
      suggestions: messages
    });
  }

  public onSuggestedMessageClick(sm: SuggestedMessage) {
    if (this.loading()) {
      return;
    }

    this.newMessage(sm.content);
  }

  public ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    window.removeEventListener('message', this.windowsEventListener, false);
  }

  private windowsEventListener = (evt?: { data: WindowInputEvent }) => {
    const data = evt?.data;
    if (!data) {
      return;
    }

    if (data.type === 'sitemule-ai-callback') {
      const organizationKey = this.organizationKey();
      if (!organizationKey) {
        throw `This should not happen`;
      }
      const payload = data.data;
      if (payload.type === 'ask-chat-gpt') {
        this.chatService
          .askChatGPT(organizationKey, payload.prompt)
          .then((val) => {
            this.postMessage({
              type: data.type,
              key: data.key,
              data: {
                ...payload,
                response: val,
              },
            });
          })
          .catch((error) => {
            this.postMessage({
              type: data.type,
              key: data.key,
              data: {
                error,
              },
            });
          });
        return;
      }
      if (payload.type === 'message') {
        this.newMessage(payload.message)
          .then((val) => {
            this.postMessage({
              type: data.type,
              key: data.key,
              data: {
                ...payload,
              },
            });
          })
          .catch((error) => {
            this.postMessage({
              type: data.type,
              key: data.key,
              data: {
                error,
              },
            });
          });
        return;
      }
      if (payload.type === 'suggestions') {
        this.setSuggestedMessages(payload.suggestions);
        this.scrollToBottom();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }
      if (payload.type === 'new-conversation') {
        this.newConversation();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }
      if (payload.type === 'download') {
        this.onDownload();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }

      if (payload.type === 'maximize') {
        this.onMaximize();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }

      if (payload.type === 'minimize') {
        this.onMinimize();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }

      if (payload.type === 'focus-input') {
        this.chatComponent.focusInput();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
          },
        });
        return;
      }

      if (payload.type === 'messages-count') {
        this.chatComponent.focusInput();
        this.postMessage({
          type: data.type,
          key: data.key,
          data: {
            ...payload,
            count: this.messages().length
          },
        });
        return;
      }
      return;
    }
  };

  private postMessage(mess: WindowOutEvent) {
    if (this.embedded) {
      window.parent.postMessage(mess, '*');
    }
  }
  public onMaximize() {
    this.maximized.set(true);
    this.postMessage({
      type: 'maximize',
    });
  }

  public onMinimize() {
    this.maximized.set(false);
    this.postMessage({
      type: 'minimize',
    });
  }

  public onClose() {
    this.postMessage({
      type: 'close',
    });
  }

  private triggerInitialized() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    setTimeout(() => {
      const html = document.querySelector('html');
      if (html) {
        html.style.scrollBehavior = 'smooth';
      }

      this.postMessage({
        type: 'initialized',
      });
    }, 100);
  }
  public linkClick(data: { link: string; label: string; }) {
    this.postMessage({
      ...data,
      type: 'message-link-click'
    });
    if (!this.embedded) {
      window.open(data.link);
    }
  }

  private scrollToBottom() {
    this.chatComponent.scrollToBottom();
  }

  private scrollToLastUserMessage() {
    this.chatComponent.scrollToLastUserMessage();
  }

  public onDownload() {
    this.downloadService.download('chat.html', `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sitemule Chat</title>
    <style>
      body {
        font-size: 16px;
      }
        table {
          border-spacing: 0;

          th {
            background: #e6e6e6;
            padding: 10px 15px;
          }
          td {
            padding: 10px 15px;
            border-bottom: 1px solid #e6e6e6;
            background: #f5f5f5;
          }
        }
    </style>
</head>
<body>
${this.messages().map(message => {
    return `<div style="margin-bottom:3rem;">
      <div style="font-weight:bold; font-size: 20px; text-transform: capitalize;">${message.role}</div>
      <div>${message.content}</div>
    </div>`
}).join('')}
</body>
</html>`);
  }
}
