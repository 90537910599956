import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

export interface AppConfig {
  primaryColor: string;
  logo: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly http = inject(HttpClient);
  getConfig(name: string) : Promise<AppConfig> {
    return firstValueFrom(this.http.get<{
      id: string;
      name: string;
      slug: string;
      primaryColor: string;
      logo: string;
    }>(`https://capa-api.bseen.ai/public/assistants/${name}`).pipe(
      map(res => {
        return {
          primaryColor: res.primaryColor,
          logo: res.logo
        };
      })
    ));
  }
}
