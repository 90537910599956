import { Routes } from '@angular/router';
import { ChatComponent } from './views/chat/chat.component';
import { LandingComponent } from './views/landing/landing.component';

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: ':organizationKey',
    component: LandingComponent,
  },
  {
    path: ':organizationKey/:threadId',
    component: ChatComponent,
  },
];
